<template>
  <TTView>
    <VRow>
      <VCol>
        <IndicatorsTableContainer />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import IndicatorsTableContainer from '../../../components/expert-assessment/indicators/IndicatorsTableContainer.vue';

export default {
  name: 'IndicatorsList',

  components: {
    IndicatorsTableContainer,
  },
};
</script>
