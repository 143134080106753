<template>
  <IndicatorsTable
    :indicators="indicators"
    :assessments="assessments"
    :loading="loading"
  >
    <template #topActions>
      <VBtn
        color="primary"
        :to="{
          name : Names.R_EXPERT_ASSESSMENTS_INDICATORS_CREATE
        }"
      >
        <VIcon left>
          fal fa-plus
        </VIcon>

        Новый индикатор
      </VBtn>

      <VDialog
        v-model="confirmDialog"
        max-width="500px"
        @click:outside="handleCancel"
      >
        <VCard>
          <VCardTitle class="headline">
            Удалить индикатор
          </VCardTitle>

          <VCardText>
            индикатор <span class="red--text">{{ indicator.name }}</span> будет удален! <br>
            Действительно хотите удалить индикатор?
          </VCardText>

          <VCardActions>
            <VSpacer />

            <VBtn
              text
              @click="handleCancel"
            >
              Нет
            </VBtn>

            <VBtn
              color="red darken-1"
              text
              @click="handleDelete"
            >
              Да
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    </template>

    <template #rowActions="{ item }">
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="blue"
            class="mx-1"
            :to="{
              name : Names.R_EXPERT_ASSESSMENTS_INDICATORS_VIEW,
              params : {
                skillAssessmentId : skillAssessmentId,
                indicatorId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="orange"
            class="mx-1"
            :to="{
              name : Names.R_EXPERT_ASSESSMENTS_INDICATORS_EDIT,
              params : {
                skillAssessmentId : skillAssessmentId,
                indicatorId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-edit
            </VIcon>
          </VBtn>
        </template>

        <span>Редактирование</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="red"
            class="mx-1"
            v-bind="attrs"
            v-on="on"
            @click.stop="deleteIndicator(item)"
          >
            <VIcon small>
              fal fa-trash
            </VIcon>
          </VBtn>
        </template>

        <span>Удаление</span>
      </VTooltip>
    </template>
  </IndicatorsTable>
</template>

<script>
import IndicatorsTable from './IndicatorsTable.vue';

export default {
  name: 'IndicatorsTableContainer',

  components: {
    IndicatorsTable,
  },

  inject: ['Names'],

  data() {
    return {
      assessments: [],
      indicators: [],
      loading: false,
      indicator: {},
      confirmDialog: false,
    };
  },

  computed: {
    skillAssessmentId() {
      return this.$route.params.assessmentId;
    },
  },
  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { skillAssessmentId } = this;
        const data = { skillAssessmentId };

        const { skillAssessments } = await this.$di.api.ExpertAssessment.skillAssessmentIndex();
        const { indicators } = await this.$di.api.ExpertAssessment.indicatorsByAssessment({ data });

        this.assessments = skillAssessments;
        this.indicators = indicators;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handleDelete() {
      try {
        this.loading = true;

        const { id } = this.indicator;
        const data = { id };

        await this.$di.api.ExpertAssessment.indicatorsDelete({ data });

        this.resetIndicatorEdit();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
        await this.fetch();
      }
    },

    resetIndicatorEdit() {
      this.confirmDialog = false;
      this.indicator = {};
    },

    handleCancel() {
      this.resetIndicatorEdit();
    },

    deleteIndicator(item) {
      this.confirmDialog = true;
      this.indicator = { ...item };
    },
  },
};
</script>
