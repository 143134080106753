<template>
  <VDataTable
    class="elevation-1"
    :headers="headers"
    :items="indicators"
    :loading="loading"
  >
    <template #top>
      <VToolbar flat>
        <VBtn
          icon
          @click="$router.go(-1)"
        >
          <VIcon>fal fa-chevron-left</VIcon>
        </VBtn>
        <VToolbarTitle>Индикаторы</VToolbarTitle>

        <VSpacer />

        <slot name="topActions" />
      </VToolbar>
    </template>

    <template #item.skillAssessmentId="{ item }">
      <div class="ma-2">
        <div class="mb-1">
          {{ assessmentName(item) }}
        </div>

        <div>
          <VChip small>
            {{ item.skillAssessmentId }}
          </VChip>
        </div>
      </div>
    </template>

    <template #item.actions="{ item }">
      <slot
        name="rowActions"
        :item="item"
      />
    </template>
  </VDataTable>
</template>

<script>
export default {
  name: 'IndicatorsTable',

  props: {
    indicators: {
      type: Array,
      required: true,
    },

    assessments: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'Название',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Вес',
          align: 'left',
          sortable: true,
          value: 'weight',
        },
        {
          text: 'Опрос',
          align: 'left',
          sortable: true,
          value: 'skillAssessmentId',
        },
        {
          text: 'Описание',
          align: 'left',
          sortable: true,
          value: 'description',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
    };
  },

  methods: {
    assessmentName(item) {
      const assessment = this.assessments.find((c) => c.id === item.skillAssessmentId);

      if (assessment) return assessment.name;

      return '';
    },
  },
};
</script>
